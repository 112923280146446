html {
    scroll-behavior: auto!important;
}
.menu-title {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.menu-icon1 {
    display: flex;
    align-items: center;
    transition: transform .3s ease;
    transform: rotateZ(0deg);
}

.loaderbox {
    position: absolute;
    width: 100%;
    background-color: #ffffff82;
    height: 100%;
    z-index: 999;
    margin: 0;
    text-align: center;
    padding-top: 100px;
    color: #3f486c;
}
.menu-icon {
    display: flex;
    align-items: center;
   
    margin-right: 8px;
}
.menu-link {
    display: flex;
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;
}
span.active .menu-icon1{
    transform: rotateZ(90deg);
    transition: transform .3s ease;
}
ul.nav-treeview {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 14px;
}
ul.nav-treeview li {
    width: 100%;
}
ul.nav-treeview li a {
    padding: 5px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
ul.nav-treeview li a:before {
    content: '\F309';
    font-family: Bootstrap-Icons;
    margin-right: 6px;
}
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: scroll;    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  body, html {
    font-size: 14px!important;
    font-family: Poppins,Helvetica,sans-serif;
    background-color: #F2F5F8;
  }
  .card {
    border: none;
    border-radius: 10px;
}
.card-body {
    padding: 30px;
}
.card-header {
    background-color: #fff;
    border-bottom: solid 2px #f3f5f8;
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 600;
}
.btn_indicator {
    display: none;
}
[data-indicator=on]>.btn_indicator {
    display: inline-block;
}
[data-indicator=on]>.btn_title {
    display: none;
}
.alert1 {
    padding: 20px;
    border: dashed 1px;
    background-color: #ddd;
    border-radius: 5px;
}
.clearbtn {
    position: absolute;
    right: 0;
    height: 100%;
    cursor: pointer;
    display: inline-flex;
    z-index: 99;
    padding: 0px 10px;
}
.clearbtn svg {
    align-self: center;
}
.width25 {
    max-width: 350px;
}

.text-blue {
    color: #2980b9;
}
.text-purple {
    color: #8e44ad;
}
.text-red {
    color: #c0392b;
}
.text-orange {
    color: #e67e22;
}
.text-yellow {
    color: #f1c40f;
}
.text-green {
    color: #27ae60;
}
.text-grey {
    color: #495252;
}


.bg-blue {
    background-color: #2980b9;
}
.bg-purple {
    background-color: #8e44ad;
}
.bg-red {
    background-color: #c0392b;
}
.bg-orange {
    background-color: #e67e22;
}
.bg-yellow {
    background-color: #f1c40f;
}
.bg-green {
    background-color: #27ae60;
}
.bg-grey {
    background-color: #495252;
}


.tabs .tab-itm {
    padding: 12px;
    display: inline-block;
    text-decoration: none;
    color: #000;
}
.tab-itm.active {
    border-bottom: solid 3px #216ba5;
    color: #216ba5;
}
.tab-itm:hover {
    border-bottom: solid 3px #ddd;
  
}

.imagebtn {
    border-radius: 50%!important;
    height: 36px;
    width: 36px;
  margin: 10px;
}
.placeholderimg {
    text-align: center;
    font-size: 80px;
    color: #d7d7d7;
    position: absolute;
    top: 50%;
    margin-top: -66px;
    height: auto;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.modalb {
    z-index: 1050!important;
}


@media only screen and (max-width: 600px) {
    nav#sidebarMenu {
        display: none;
    }
}

@page  
{ 
    size: auto;   /* auto is the initial value */ 

    /* this affects the margin in the printer settings */ 
    margin: 10mm 10mm 10mm 10mm;  
} 